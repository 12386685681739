<template>
	<div>
		<div class="border border-solid border-blue-200">
			<div class="flex flex-col">
				<div class="duration-300">
					<datatable
						:data="settlements.data"
						:columns="columns"
						:fillable="false"
						:loading="settlements.loading"
						:actions="actions"
						ref="table"
					/>
				</div>
			</div>
		</div>
		<div>
			<modal
			className="w-full md:w-2/5 xl:w-3/10 pt-10"
			ref="addCardModal"
			@close="removeError"
			>
			<h4 class="text-xl font-bold mb-6">Add Transaction Reference</h4>

			<template v-if="requestError">
				<div class="alert alert-red-soft mb-10">
				<div class="alert-icon">!</div>
				<span>{{ requestError }}</span>
				</div>
			</template>

			<div class="mb-6">Transaction Reference</div>

			<div class="flex flex-row items-center mb-6">
				<form-group
				v-model="transactionReference"
				placeholder="Enter Transaction Reference"
				class="custom-select border border-blue-500 rounded-sm text-xs text-blue-500 w-full"
				/>
			</div>

			<div class="flex flex-row items-center">
				<button
					type="button"
					class="btn btn-md btn-red-soft mr-2"
					@click.prevent="closeActivatePndModal"
				>
				Cancel
				</button>
				<button
				type="button"
				class="btn btn-blue btn-md"
				@click.prevent="addCard"
				>
				<span v-if="loading">Resolving</span>
				<span v-else>Confirm</span>
				</button>
			</div>
			</modal>
		
			<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">
				<img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

				<div class="text-lg font-bold mb-4">
					Success!
				</div>
				<div class="text-sm mb-10">
					Payout Updated Successfully.
				</div>

				<button type="button" class="btn btn-blue" @click.prevent="$refs.successModal.close">
					Okay
				</button>
			</modal>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		query: String,
	},
	data() {
		return {
			settlements: this.$options.resource([]),
			transactionReference: '',
			payoutId: '',
			columns: [
				{
					name: "merchant",
					th: "Merchant",
					render: (settlement) => settlement?.merchant?.name,
				},
				{
					name: "reference",
					th: "Reference",
				},
				{
					name: "amount",
					th: "Settlement Amount",
					render: (settlement) => this.$options.filters.formatAmount(
						settlement?.amount,
					)
				},
				{
				name: "transaction_amount",
				th: "Transaction Amount",
				render: (settlement) => this.$options.filters.formatAmount(
					settlement?.transaction_amount,
				)
				},
				{
				name: "refund_amount",
				th: "Refund Amount",
				render: (settlement) => this.$options.filters.formatAmount(
					settlement?.refund_amount
				)
				},
				{
					name: 'created_at',
					th: 'Created At',
					render: (settlement) =>
						this.$moment(settlement?.created_at).format('dddd, MMMM Do YYYY, h:mm:ss a'),
				},
				{
					name: "status",
					th: "Status",
					render: () => {
						return `<div class="badge badge-orange-soft-outline px-7">
		                  Pending
		                </div>`
					}
				},
			],
			actions: [
			{
				text: 'Mark As Paid',
				class: 'border-blue-500 text-blue-500 rounded-sm px-4 py-2',
				action: this.openAddCardModal,
			},
        ],
		};
	},
	beforeMount() {
		this.pendingSettlement();
	},
	methods: {
		async pendingSettlement() {
			this.settlements.loading = true;
			await this.sendRequest("admin.merchants.payouts.pending", {
				success: (response) => {
					this.settlements.data = response.data.data;
				},
				error: (error) => {
					console.log(error);
				},
			});
			this.settlements.loading = false;
		},
		openAddCardModal(payout) {
			this.payoutId = payout.id;
			this.$refs.addCardModal.open();
		},
		closeAddCardModal() {
			this.$refs.addCardModal.close();
		},
		async addCard() {
			this.loading = true;
			await this.sendRequest(
				'admin.merchants.payouts.payoutSettlement',
				{
				data: {
				id: this.payoutId,
				settlementReference: this.transactionReference,
				},
				success: () => {
				this.closeAddCardModal();
				this.$refs.successModal.open();
				},
				error: (error) => {
				this.requestError = error.response?.data?.message;
				},
			});
			this.loading = false;
		},
	},
};
</script>